import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Person from '../../../Person/Person'
import Divider from '@solid-ui-components/Divider'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block01'
import Hero2 from '@solid-ui-blocks/Hero/Block02'
import Content from '@solid-ui-blocks/Content/Block02'
import FeatureOne from '@solid-ui-blocks/FeaturesWithPhoto/Block01'
import Download from '@solid-ui-blocks/CallToAction/Block02'
import Testimonials from '@solid-ui-blocks/Testimonials/Block01'
import Faq from '@solid-ui-blocks/Faq/Block01'
import Gallery from '@solid-ui-blocks/Blog/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from './_theme'
import styles from './_styles'

const IndexPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='Implantes Dentários e Prótese de Protocolo - Dentista Ceilândia' />
      <Person type='dentist' />
      <Person type='person' />
      {/* Blocks */}
      <Header content={content['header']} />
      <Divider space='4' />
      <Container variant='full' sx={styles.heroContainer}>
        <Hero content={content['hero']} />
      </Container>
      <Divider space='4' />
      <FeatureOne content={content['feature-one']} reverse />
      <Divider space='3' />
      <Divider space='3' />
      <Download content={content['download']}/>
      <Divider space='4' />
      <Content content={content['services-intro']} />
      <Divider space='4' />
      <Gallery content={content['gallery-row1']} />
      <Gallery content={content['gallery-row2']} />
      <Divider space='5' />
      <Testimonials content={content['testimonials']} />
      <Divider space='4' />
      <Download content={content['download']} />
      <Divider space='5' />
      <Faq content={content['faq']} />
      <Divider space='5' />
      <Hero2 content={content['realize']} reverse />
      <Divider space='5' />
      <Download content={content['location']} reverse/>
      <Divider space='3' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query homepageRealizeBlockContent {
    allBlockContent(
      filter: { page: { in: ["realize/lp-implante"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage
